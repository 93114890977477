import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { EnsembleWidgetData } from "../../config/interfaces";
import ScriptActionsMenu from "../ActionMenus/ScriptActionsMenu";

interface ScriptCardProps {
  script: EnsembleWidgetData;
  app_id: string;
  isReadOnly: boolean;
}

const ScriptCard: React.FC<ScriptCardProps> = ({
  script,
  app_id,
  isReadOnly,
}) => {
  return (
    <Link to={`/app/${app_id}/script/${script.id}`}>
      <div className="script-screen-card">
        <div
          className="space-between"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <div className="script-screen-card__content">
              <h4 style={{ marginBottom: 0 }}>{script.name}</h4>
            </div>
            <div className="screen-card__content-info">
              {script.description && <span>{script?.description}</span>}
            </div>
          </div>

          <div>
            {!isReadOnly && (
              <ScriptActionsMenu script={script} isReadOnly={isReadOnly} />
            )}
          </div>
        </div>
      </div>
      <ReactTooltip effect="solid" place="top" />
    </Link>
  );
};

export default ScriptCard;
