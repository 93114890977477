import React, {
  useState,
  createRef,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../pages/AppPagesWrapper";
import { formatWidgetName } from "../../utils/functions";

// APIs
import { useCreateWidget } from "../../hooks/useFirebase";
import { AuthContext } from "../../App";

interface WidgetCreateModalProps {
  onCancel: () => void;
}

const initialContent = `
Widget:
  inputs:
    - customProperty
  body:
    Column:
      children:
        - Text:
            text: ${"${customProperty}"}
`;

const WidgetCreateModal: React.FC<WidgetCreateModalProps> = () => {
  const navigate = useNavigate();
  const { app } = useAppContext();
  const { currentUser } = useContext(AuthContext);
  const [widgetNameInput, setWidgetNameInput] = useState("");
  const [widgetDescriptionInput, setWidgetDescriptionInput] = useState("");

  const widget = useMemo(
    () => ({
      id: "",
      name: widgetNameInput,
      description: widgetDescriptionInput,
      content: initialContent,
      isArchived: false,
      isRoot: false,
    }),
    [widgetNameInput, widgetDescriptionInput],
  );

  const createWidgetQuery = useCreateWidget(currentUser, app.id, widget);
  const widgetNameInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    widgetNameInputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const screenNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const formattedName = formatWidgetName(event.currentTarget.value);
    setWidgetNameInput(formattedName);
  };

  const widgetDescriptionChange = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    setWidgetDescriptionInput(event.currentTarget.value);
  };

  const handleCreateWidget = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (app.internalWidgets?.find((widget) => widget.name === widgetNameInput))
      alert(`Widget name ${widgetNameInput} already exist`);
    else createWidgetQuery.mutate();
  };

  useEffect(() => {
    if (createWidgetQuery.isSuccess) {
      const newWidgetId = createWidgetQuery.data?.insert_screen.returning[0].id;
      navigate(`/app/${app.id}/widget/${newWidgetId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createWidgetQuery.status]);

  return (
    <React.Fragment>
      <form className="screen-create-modal" onSubmit={handleCreateWidget}>
        <label>Widget name</label>
        <input
          type="text"
          value={widgetNameInput}
          placeholder="Enter widget name"
          onChange={screenNameChange}
          ref={widgetNameInputRef}
        />

        <label>Widget description (optional)</label>
        <input
          type="text"
          value={widgetDescriptionInput}
          placeholder="Enter widget description"
          onChange={widgetDescriptionChange}
        />

        <button
          className="button__primary"
          type="submit"
          disabled={!widgetNameInput || createWidgetQuery.isLoading}
        >
          {createWidgetQuery.isIdle && <>Create widget</>}
          {createWidgetQuery.isLoading && <>Processing...</>}
        </button>
      </form>
    </React.Fragment>
  );
};

export default WidgetCreateModal;
