import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAppContext } from "./AppPagesWrapper";

// Components
import TemplateScreenCard from "../components/TemplateScreenCard";
import { useGetTemplateScreens } from "../hooks/useAPIs";
import assets_empty_state from "../assets/assets_empty_state.png";
import { generateEDLFromImage } from "../utils/assistantUtils";
import { Modal, Spin } from "antd";
import "./CreateAppScreen.sass";
import { useNavigate } from "react-router-dom";
import { RemixIcon } from "../components/Widgets";
import { EnsembleScreenData } from "../config/interfaces";
import { useQueryClient } from "react-query";

type Status = "completed" | "error" | "running";
interface CreateScreenResult {
  status: Status;
  output?: Context;
}
interface Context {
  [key: string]: any;
}

const CreateAppScreen: React.FunctionComponent = () => {
  const queryClient = useQueryClient();
  const { app, isAppReadOnly } = useAppContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<boolean>(false);
  const [screenNameInput, setScreenNameInput] = useState("");
  const [screenDescriptionInput, setScreendescriptionInput] = useState("");
  const screenDetailsQuery = useGetTemplateScreens();
  const blankScreenData = new EnsembleScreenData(
    "blank-1",
    "Blank Screen",
    false,
    false,
    "",
    "",
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [status, setStatus] = useState<Status | null>(null);
  const [newScreenId, setNewScreenId] = useState<string | null>(null);

  useEffect(() => {
    if (app.name) document.title = app.name;
  });

  const screenNameChange = (event: React.FormEvent<HTMLInputElement>): void => {
    setError(false);
    setScreenNameInput(event.currentTarget.value);
  };

  const screenWidgetDescriptionChange = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    setScreendescriptionInput(event.currentTarget.value);
  };

  const validateScreenName = () => {
    setError(true);
    inputRef.current?.focus();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (
      file &&
      [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/webp",
      ].includes(file.type)
    ) {
      setStatus("running");
      generateEDLFromImage(
        app.id,
        file,
        screenNameInput !== "" ? screenNameInput : undefined,
      )
        .then((res) => {
          const result = res.data as CreateScreenResult;
          if (result) {
            // setData(result.output);
            console.log(`RESULT: ${JSON.stringify(result)}`);
            setStatus(result.status);
            if (result.status === "completed") {
              queryClient.invalidateQueries(["app", app.id]).then(() => {});

              if (result.output?.screenId) {
                setNewScreenId(result.output!.screenId);
              }
            }
          }
        })
        .catch(() => {
          setStatus("error");
        });
    } else {
      alert("Please select a valid image file (png, jpg, jpeg, gif, webp).");
    }
  };

  return (
    <div className="screen-content" key={app.id}>
      <div className="page-header create-header">
        <div>
          <div className={`input-box ${error ? "error" : ""}`}>
            <label>Enter Screen name</label>
            <input
              type="text"
              autoFocus
              ref={inputRef}
              value={screenNameInput}
              onChange={screenNameChange}
            />
            <span className="input-box__error">Screen name is required</span>
          </div>
          <div
            style={{
              marginTop: 12,
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            <label style={{ fontSize: 14 }}>
              Enter Screen description (optional)
            </label>
            <input
              type="text"
              value={screenDescriptionInput}
              onChange={screenWidgetDescriptionChange}
            />
          </div>
        </div>
      </div>

      <div className="screen-cards-ct">
        {/* upload card */}
        <div
          className="template-screen-card upload-screen-card"
          onClick={() => fileInputRef.current!.click()}
        >
          <div className="upload-screen-card__image">
            <img src={assets_empty_state} alt="empty assets" />
          </div>
          <div className="template-screen-card__content">
            <h4>Uploading an image</h4>
            <span>to auto-generate the screen</span>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg,.gif,.webp"
            onChange={handleFileChange}
          />
        </div>

        {/* blank card */}
        <TemplateScreenCard
          key={blankScreenData.id}
          screenName={screenNameInput}
          screenDescription={screenDescriptionInput}
          screen={blankScreenData}
          app_id={app.id}
          isReadOnly={isAppReadOnly}
          validateScreenName={validateScreenName}
        />
      </div>

      <div className={"select-template-header"}>
        or select a Template to begin
      </div>
      <div className="screen-cards-ct">
        {screenDetailsQuery.data?.screens &&
          screenDetailsQuery.data?.screens?.length > 0 && (
            <>
              {screenDetailsQuery.data?.screens
                // ?.filter((f) =>
                //   selectedCategories === "All"
                //     ? true
                //     : f.category === selectedCategories,
                // )
                .map((scr) => (
                  <TemplateScreenCard
                    key={scr.name}
                    screenName={screenNameInput}
                    screenDescription={screenDescriptionInput}
                    screen={scr}
                    app_id={app.id}
                    isReadOnly={isAppReadOnly}
                    validateScreenName={validateScreenName}
                  />
                ))}
            </>
          )}
      </div>

      {status && (
        <Modal footer={null} closable={false} open={true} centered>
          {status === "error" ? (
            <div>
              <RemixIcon name={"error-warning"} size={24} color={"#FF0000"} />
              <p>Sorry I ran into an error</p>
              <button
                className="button__primary"
                onClick={() => navigate(`/app/${app.id}/screens`)}
              >
                Back to App
              </button>
            </div>
          ) : status === "completed" ? (
            newScreenId ? (
              <div>
                <p>Screen generated successfully.</p>
                <button
                  className="button__primary"
                  onClick={() =>
                    navigate(`/app/${app.id}/screen/${newScreenId}`)
                  }
                >
                  Go to screen
                </button>
              </div>
            ) : (
              <div>
                <p>Completed but cannot generate the screen</p>
                <button
                  className="button__primary"
                  onClick={() => navigate(`/app/${app.id}/screens`)}
                >
                  Back to App
                </button>
              </div>
            )
          ) : (
            <div>
              <Spin size="large" />
              <p>Generating screen ...</p>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default CreateAppScreen;
