import React, { useState, useEffect, useContext } from "react";
import { EnsembleScreenData } from "../config/interfaces";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import { useCreateScreens } from "../hooks/useAPIs";

// Assets
import default_screen_image from "../assets/image_placeholder.svg";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useAppContext } from "../pages/AppPagesWrapper";
import { starterScreen } from "../utils/templateUtils";

interface TemplateScreenCardProps {
  screen: EnsembleScreenData;
  screenName: string;
  screenDescription: string;
  app_id: string;
  isReadOnly: boolean;
  validateScreenName: () => void;
}

const TemplateScreenCard: React.FC<TemplateScreenCardProps> = ({
  screen,
  screenName,
  screenDescription,
  app_id,
  validateScreenName,
}) => {
  const storage = getStorage();
  const { app } = useAppContext();

  const [image, setImage] = useState(default_screen_image);
  const [makeThisRoot, setMakeThisRoot] = useState(false);

  useEffect(() => {
    // check if this screen should be set to root
    const isFirstScreen = app?.screens?.length === 0;
    setMakeThisRoot(isFirstScreen);

    if (screen.id === "blank-1") {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      setImage(require("../assets/template_blank.jpg"));
    } else {
      getDownloadURL(ref(storage, `screens/${screen.id}.jpg`))
        .then((url) => {
          setImage(url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  const screenData = {
    name: screenName,
    app_id: app_id,
    description: screenDescription,
    isRoot: makeThisRoot,
    content: screen.content || starterScreen,
    isArchived: false,
  };
  const createScreenQuery = useCreateScreens(currentUser, app_id, [screenData]);

  const copyTemplateToScreen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!screenName?.trim()) {
      validateScreenName();
      return;
    }
    createScreenQuery.mutate();
  };

  useEffect(() => {
    if (createScreenQuery.isSuccess) {
      const newScreenId = createScreenQuery.data?.insert_screen.returning[0].id;
      navigate(`/app/${app_id}/screen/${newScreenId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createScreenQuery.status]);

  return (
    <div onClick={copyTemplateToScreen}>
      <div className="template-screen-card">
        <div className="template-screen-card__content">
          <h4>{screen.name}</h4>
        </div>
        <div
          className="template-screen-card__image"
          style={{ backgroundImage: `url(${image}` }}
        ></div>
        <button
          onClick={copyTemplateToScreen}
          className="button__primary templateBtn"
          disabled={createScreenQuery.isLoading}
        >
          {createScreenQuery.isIdle && <>Use Template</>}
          {createScreenQuery.isLoading && <>Processing...</>}
        </button>
      </div>
    </div>
  );
};

export default TemplateScreenCard;
